import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import React from 'react';
import styled from 'styled-components';
import ImgNotice9 from './imgNotice9.png';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 128px;

  .order {
    display: flex;
    gap: 8px;
    margin-bottom: ${SpaceValue.XL}px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: ${V2BackgroundColors.disabled};
      color: ${V2TextColors.disabled};

      &.order-on {
        background-color: ${V2BackgroundColors.accent_green_default};
        color: ${V2TextColors.inverse};
      }
    }
  }

  .guide-text {
    white-space: break-spaces;
  }

  .notice-img {
    width: fit-content;
    height: auto;
    object-fit: contain;
  }
`;

const NoticeFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: ${SpaceValue.XXL}px ${SpaceValue.L}px;

  > div {
    border-bottom: 1px solid ${V2BorderColors.default};

    &:last-child {
      border-bottom: none;
    }

    &:not(:first-child) {
      padding-top: ${SpaceValue.XXXL}px;
    }
  }
`;

interface VideoRecordNoticeProps {
  title: string;
  subTitle: string;
  notice: string;
  guideText: string;
  img: string;
}

const PhotoNotice = ({ title, subTitle, guideText, img, notice }: VideoRecordNoticeProps) => {
  return (
    <Frame>
      <V2Text
        fontStyle={V2FontStyleType.body_3_sb}
        color={V2TextColorsType.brand}
      >
        {title}
      </V2Text>
      <SpacingBlock size={SpaceValue.XXXS} vertical />
      <V2Text
        fontStyle={V2FontStyleType.title_1_b}
        color={V2TextColorsType.default}
      >
        {subTitle}
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.danger}
        className='guide-text'
      >
        {notice}
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.subtle}
        className='guide-text'
      >
        {guideText}
      </V2Text>
      <SpacingBlock size={SpaceValue.XL} vertical />
      <img className='notice-img' src={img} alt='영상 가이드 이미지' />
    </Frame>
  );
};

const videoRecordDailyPhotosGuide = () => {
  return (
    <NoticeFrame>
      <PhotoNotice
        title='일상 사진 가이드'
        subTitle='나를 표현할 수 있는 사진'
        notice={`얼굴이 중심적으로 나오지 않아도 괜찮습니다.\nEx. 여행을 좋아할 경우, 여행 중 촬영한 내 모습`}
        guideText={`평소 모습이나 나를 잘 나타낼 수 있는 사진 3장을 첨부해주세요. 나만의 분위기를 프로필에 연출할 수 있어요.`}
        img={ImgNotice9}
      />
    </NoticeFrame>
  );
};

export default videoRecordDailyPhotosGuide;
