/* eslint-disable jsx-a11y/media-has-caption */
import { JDModalButton } from 'components/_v2/_common/modals/JDModalComponents';
import colors from '__designkit__/common/colors';
import Fonts, { fontStyle } from '__designkit__/common/fonts';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import React, { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import JDAModalFrame from 'components/modals/JDAModalFrame';
import JDHorizonModal from 'components/modals/JDHorizonModal';
import ImgGuide1 from './ImgModalGuide.png';
import ImgGuide2 from './ImgModalGuide2.png';
import ImgGuide4 from './ImgModalGuide4.png';
import VideoGuide3 from './video_best_case.mp4';
//* * 일림관련 모달 */

export enum DialogType {
  NOTICE = 'NOTICE',
  CONFIRM = 'CONFIRM',
  DEFAULT = 'DEFAULT',
  ALERT = 'ALERT'
}

interface IVideoRecordGuideHorizontalModalProps extends IModalFrameProps {
  onOk:() => void;

}
const DialogFooter = styled.div`
    display: flex;
    gap:8px;
    width: 100%;
    margin-top: 24px;
`;
const ModalFrame = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  width:360px;
  .video-fit{
    width: 100%;
  }
  color:${colors.CG_80};
  .detail-are{
    padding:${SpaceValue.L}px ${SpaceValue.L}px ${SpaceValue.L}px ${SpaceValue.L}px;
    .title-area{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .btn-ok {
    margin-top:32px;
    min-height:44px;
  }
  .btn-cancel {
    margin-top:15px;
  }
  .content {
    font: ${Fonts.B2_Medium};
    color:${colors.CG_70};
    margin-top:16px;
    text-align:center;
  }
  }
  
`;
const detailList = [
  {
    img: ImgGuide1,
    title: '촬영 구도 가이드',
    desc: '카메라를 가로 방향으로 돌려, 삼각대에 거치 해주세요. 전면카메라 모드로 촬영 세팅을 시작합니다.',
  },
  {
    img: ImgGuide2,
    title: '촬영 구도 가이드',
    desc: '카메라 높이 : 삼각대 높이를 조절해, 눈높이를 맞춰주세요.(촬영 TIP : 눈높이보다 살짝 높게 설치 해주세요.)',
  },
  {
    img: ImgGuide4,
    title: '답변 태도 가이드',
    desc: '베스트 영상을 참고하여 촬영을 준비해 주세요 !(복장 · 배경 · 스크립트 암기 · 미소 등)',
  },
  {
    img: ImgGuide4,
    title: '답변 태도 가이드',
    desc: '촬영 버튼을 클릭하면, 5초 후 촬영이 시작됩니다. 촬영이 시작되기 이전, 미리 밝은 표정을 준비해 주세요.',
  },
];
const VideoRecordGuideHorizontalModal:FC<IVideoRecordGuideHorizontalModalProps> = ({ onOk, ...props }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const handleClick = () => {
    if (currentStep < detailList.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onOk();
    }
  };
  const handleClickPrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  return (
    <JDHorizonModal horizonMode dimmed isFrame {...props}>
      <ModalFrame>
        {
          currentStep === 2
            ? (
              <video className='video-fit' playsInline controls loop>
                <source src={`${VideoGuide3}#t=0.001`} type='video/mp4' />
              </video>
            )
            : <img src={detailList[currentStep].img} alt='guide' />
        }

        <div className='detail-are'>
          <div className='title-area'>
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_1_b}
              color={V2TextOption.color.default}
            >
              {detailList[currentStep].title}
            </V2Text>
            <V2Text
              fontStyle={V2TextOption.fontStyle.body_1_m}
              color={V2TextOption.color.subtlest}
            >
              0{currentStep + 1} / 04
            </V2Text>
          </div>
          <SpacingBlock vertical size={12} />
          <V2Text
            fontStyle={V2TextOption.fontStyle.body_2_m}
            color={V2TextOption.color.subtle}
          >
            {detailList[currentStep].desc}
          </V2Text>
          <SpacingBlock vertical size={16} />
          <DialogFooter>
            {
              currentStep > 0 && (
                <V2Button
                  type={V2ButtonOption.type.OutLine}
                  size={V2ButtonOption.size.M}
                  fontStyle={V2ButtonOption.fontStyle.body_1_sb}
                  fill={V2ButtonOption.fillType.neutral_subtle_default}
                  color={V2ButtonOption.color.subtle}
                  buttonProps={{ onClick: handleClickPrev }}
                  styles={{ width: 88 }}
                >
                  이전
                </V2Button>
              )
            }
            <V2Button
              type={V2ButtonOption.type.Fill}
              size={V2ButtonOption.size.M}
              fontStyle={V2ButtonOption.fontStyle.body_1_sb}
              fill={V2ButtonOption.fillType.brand_strong_default}
              color={V2ButtonOption.color.inverse}
              buttonProps={{ onClick: handleClick }}
              styles={{ width: '100%' }}
            >
              {currentStep < 3 ? '다음' : '모두 확인했어요'}
            </V2Button>
          </DialogFooter>
        </div>
      </ModalFrame>
    </JDHorizonModal>
  );
};

export default VideoRecordGuideHorizontalModal;
