/* eslint-disable prefer-destructuring */
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import React, { useState } from 'react';
import styled from 'styled-components';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import request from 'utils/request';
import { IAttachFile } from 'interfaces/_v2/profile/IProfileEducation';
import URIs from 'consts/URIs';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import NoticeDialog from 'components/modals/NoticeDialog';
import IconChange from './icon_change.svg';
import { VideoAndImagesType } from './VideoRecordIntro';
import { DialogType } from './VideoRecordGuideHorizontalModal';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: ${SpaceValue.XXL}px ${SpaceValue.L}px 128px ${SpaceValue.L}px;
  
  .file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${SpaceValue.XS}px;
    margin: ${SpaceValue.XL}px 0 ${SpaceValue.L}px 0;
    height: 240px;
    border: 1px solid ${V2BorderColors.default};
    border-radius: ${RadiusValue.L}px;
    background-color: white;

    &.has-upload {
      background-color: black;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .file-change {
    position: absolute;
    right: 12px;
    bottom: 12px;
    
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

interface IVideoRecordDailyPhotos{
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}
const VideoRecordDailyPhotos: React.FC<IVideoRecordDailyPhotos> = ({ setCurrentStep }) => {
  const [images, setImages] = useState<(File | null)[]>([null, null, null]); // 각 업로드된 파일들
  const [previewUrls, setPreviewUrls] = useState<(string | null)[]>([null, null, null]); // 각 미리보기 URL
  const [isSubmitModal, setIsSubmitModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleImageUpload = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const newImages = [...images];
      newImages[index] = file;
      setImages(newImages);

      const newUrls = [...previewUrls];
      newUrls[index] = URL.createObjectURL(file);
      setPreviewUrls(newUrls);
    }
  };

  const handleUpload = async () => {
    if (images.length === 0) {
      alert('업로드할 이미지를 선택해주세요.');
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append('type', VideoAndImagesType.MATCHING_SELF_EXPRESSION_PHOTO);
    images.forEach((image) => {
      if (image) { // null 체크
        formData.append('files', image);
      }
    });

    try {
      const response = await request<IAttachFile[]>({
        method: 'post',
        url: URIs.post_v1_match_applicants_photos,
        data: formData,
      });

      if (response[0].fileUid) {
        setCurrentStep(0);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('업로드 중 오류 발생:', error);
    }
  };

  return (
    <Frame>
      <V2Text
        fontStyle={V2FontStyleType.body_3_sb}
        color={V2TextColorsType.brand}
      >
        일상 사진 가이드
      </V2Text>
      <SpacingBlock size={SpaceValue.XXXS} vertical />
      <V2Text
        fontStyle={V2FontStyleType.title_1_b}
        color={V2TextColorsType.default}
      >
        나를 표현할 수 있는 사진
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.danger}
      >
        얼굴이 중심적으로 나오지 않아도 괜찮습니다.
        <br />
        Ex. 여행을 좋아할 경우, 여행 중 촬영한 내 모습
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.subtle}
      >
        평소 모습이나 나를 잘 나타낼 수 있는 사진 3장을 첨부해주세요. 나만의 분위기를 프로필에 연출할 수 있어요.
      </V2Text>

      {/* 업로드 Input */}
      <div className='file-wrap'>
        {[0, 1, 2].map((index) => (
          <div
            key={index}
            className={`file-upload ${previewUrls[index] ? 'has-upload' : ''}`}
          >
            {previewUrls[index] ? (
              <img src={previewUrls[index] ?? undefined} alt='Uploaded Preview' />
            ) : (
              <>
                <V2Icon name='add' />
                <V2Text fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.default}>
                  사진 추가하기
                </V2Text>
              </>
            )}
            <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload(index)} />
            {previewUrls[index] && (
            <div className='file-change'>
              <img src={IconChange} alt='사진 수정' />
              <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload(index)} />
            </div>
            )}
          </div>
        ))}
      </div>
      <div className='action-buttons'>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          buttonProps={{ onClick: () => setIsSubmitModal(true), disabled: images.includes(null) }}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
        >
          제출하기
        </V2Button>
      </div>
      <NoticeDialog
        dialogType={DialogType.NOTICE}
        title='최종제출 하시겠습니까?'
        content='최종제출 후 수정은 불가능합니다.'
        isOpen={isSubmitModal}
        onOk={() => {
          handleUpload();
        }}
        onCancel={() => setIsSubmitModal(false)}
        firstButtonText='제출하기'
        secondButtonText='나가기'
        isLoading={isLoading}
      />
    </Frame>
  );
};

export default VideoRecordDailyPhotos;
