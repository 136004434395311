/* eslint-disable prefer-destructuring */
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import React, { useState } from 'react';
import styled from 'styled-components';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { boolean } from 'yup';
import request from 'utils/request';
import URIs from 'consts/URIs';
import FileUtil from 'utils/FileUtil';
import IconDumy from 'assets/_v2/profile/img_profile_base_info_dumy.png';
import V2Button from '__pc__/components/common/v2Design/button';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import NoticeDialog from 'components/modals/NoticeDialog';
import IconChange from './icon_change.svg';
import ImageCropModal from '../_common/crop/ImageCropModal';
import { VideoAndImagesType } from './VideoRecordIntro';
import { DialogType } from './VideoRecordGuideHorizontalModal';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: ${SpaceValue.XXL}px ${SpaceValue.L}px 128px ${SpaceValue.L}px;

  .file-wrap {
    position: relative;
    margin: ${SpaceValue.XL}px auto 0;
    width: 240px;
    height: 240px;
  }

  .file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${SpaceValue.XS}px;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    border: 1px solid ${V2BorderColors.default};

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 240px;
      height: 240px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .file-change {
    position: absolute;
    right: 0;
    bottom: 0;
    
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

interface IAttachFile {
  fileName: string;
  fileUid: string;
  fileUrl: string;
  filePath: string;
}

interface IVideoRecordProfilePhotos {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}
const VideoRecordProfilePhotos: React.FC<IVideoRecordProfilePhotos> = ({ setCurrentStep }) => {
  const [images, setImages] = useState<File[]>([]); // 업로드된 파일들
  const [previewUrls, setPreviewUrls] = useState<string[]>([]); // 미리보기 URL
  const [imageCropModalOpen, setImageCropModalOpen] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const [imgName, setImgName] = useState('');
  const [isSubmitModal, setIsSubmitModal] = useState(false);

  const setFile = async (file: File, cropOn?: boolean) => {
    setImages([file]);
    setPreviewUrls([URL.createObjectURL(file)]);
    setProfileImageUrl(URL.createObjectURL(file));
    setImgName(file.name);
    if (cropOn) setImageCropModalOpen(true);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.item(0)!) {
      setFile(e.target.files?.item(0)!, true);
      e.target.value = ''; // 동일한 파일 올릴 수 있도록 초기화
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setImages((prev) => [file]);
      setPreviewUrls([URL.createObjectURL(file)]);
    }
  };

  const changeImage = (canvas: HTMLCanvasElement) => {
    const file = FileUtil.convertCanvasToFile(canvas.toDataURL(), imgName);
    setFile(file);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    images.forEach((image) => {
      if (image) {
        formData.append('files', image);
      }
    });

    formData.append('type', VideoAndImagesType.MATCHING_PROFILE_PHOTO);
    try {
      const res = await request<IAttachFile[]>({
        method: 'post',
        url: URIs.post_v1_match_applicants_photos,
        data: formData,
      });

      if (res[0].fileUrl) {
        setCurrentStep(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Frame>
      <V2Text
        fontStyle={V2FontStyleType.body_3_sb}
        color={V2TextColorsType.brand}
      >
        프로필 사진 가이드
      </V2Text>
      <SpacingBlock size={SpaceValue.XXXS} vertical />
      <V2Text
        fontStyle={V2FontStyleType.title_1_b}
        color={V2TextColorsType.default}
      >
        대표 프로필 가이드
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.danger}
      >
        가이드에 벗어난 프로필 사진은 불합격 처리 됩니다.
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.subtle}
      >
        대표 프로필은, 지원서 내 메인 이미지로 게시됩니다.
        <br />
        이래 예시 가이드에 맞추어 제출해주세요.
      </V2Text>

      <div className='file-wrap'>
        <div className='file-upload'>
          {previewUrls.length > 0 ? (
            <img src={previewUrls[0]} alt='Uploaded Preview' />
          ) : (
            <>
              <V2Icon name='add' />
              <V2Text fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.default}>
                사진 추가하기
              </V2Text>
            </>
          )}
          <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload} />
        </div>

        {previewUrls.length > 0 && (
          <div className='file-change'>
            <img src={IconChange} alt='사진 수정' />
            <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload} />
          </div>
        )}
      </div>
      <ImageCropModal
        imgSrc={profileImageUrl || IconDumy}
        imgName={images[0]?.name}
        isOpen={imageCropModalOpen}
        onClickClose={() => setImageCropModalOpen(false)}
        width={window.innerWidth ? window.innerWidth : 360}
        height={560}
        onChange={changeImage}
      />
      <div className='action-buttons'>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          buttonProps={{ onClick: () => setIsSubmitModal(true), disabled: images.length < 1 }}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
        >
          제출하기
        </V2Button>
      </div>
      <NoticeDialog
        dialogType={DialogType.NOTICE}
        title='최종제출 하시겠습니까?'
        content='최종제출 후 수정은 불가능합니다.'
        isOpen={isSubmitModal}
        onOk={() => {
          handleUpload();
        }}
        onCancel={() => setIsSubmitModal(false)}
        firstButtonText='제출하기'
        secondButtonText='나가기'
      />
    </Frame>
  );
};

export default VideoRecordProfilePhotos;
