import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import SignupCongratulateModal from 'components/_v2/_common/modals/SignupCongratulateModal';
import BuilderAtsErrorModal from 'components/_v2/builder/BuilderAtsErrorModal';
import JDInput from 'components/_v2/join/JDInput';
import JoinTermsModal from 'components/_v2/join/JoinTermsModal';
import { birthDate15Validator, passwordValidator, rePasswordValidator } from 'consts/ValidationRule';
import { countryList } from 'consts/_v2/join/countryList';
import useToast from 'hooks/useToast';
import { SocialType } from 'interfaces/_v2/join/IJoinRqRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import JoinModel from 'models/_v2/JoinModel';
import ResumeModel from 'models/_v2/ResumeModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import { getSession, removeSession } from 'utils/CommonUtils';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import makeEmailList from 'utils/_v2/emailAutoComplete';
import { convertMsToMMSS } from 'utils/_v2/timeUtils';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import * as yup from 'yup';
import JDRadioInput, { JD_RADIO_INPUT_TYPE } from 'components/_v2/_common/input/JDRadioInput';
import { GenderType } from 'consts/_v2/join/signUpType';
import RoutePaths from 'consts/RoutePaths';
import { DateFormatYYYYMMDDWithDot } from 'utils/DateUtils';
import JDInputSelector from './JDInputSelector';

const slideIn = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const Frame = styled.div`
  width: 100%;
  height: fit-content;
  color: ${Colors.JOBDA_BLACK};
`;

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  font: ${Fonts.H5_Bold};

  & > div {
    position: absolute;
    left: 16px;
  }
`;

const TitleText = styled.div`
  width: 100%;
  height: fit-content;
  padding: 16px;
  font: ${Fonts.H4_Bold};
  white-space: pre-wrap;
  animation: ${slideIn} .5s ease-in-out;
`;

const Contents = styled.div`
  width: 100%;
  height: fit-content;
  padding: 16px;
  animation: ${slideIn} .5s ease-in-out;
`;
const BirthGenderFrame = styled.div`
  display: flex;
  align-items: center;
`;
const BirthdayBox = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: fit-content;

  > div.dash {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    margin: 0 12px;
    
    > span {
      display: inline-block;
      width: 8px;
      border-top: 1px solid ${Colors.JOBDA_BLACK};
    }
  }
`;

const GenderBox = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
`;

const PhoneBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InnerButtonInput = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  > div.postFix {
    position: absolute;
    top: 7px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const InnerButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${Colors.JOBDA_BLACK};
  font: ${Fonts.B3_Bold};
  color: ${Colors.WHITE_100};

  &:active {
    background-color: ${Colors.CG_70};
  }

  &:disabled {
    background-color: ${Colors.CG_40};
    color: ${Colors.CG_60};
  }
`;

const Timer = styled.div`
  margin-right: 12px;
  font: ${Fonts.B2_Medium};
  color: ${Colors.ERROR};
`;

const Footer = styled.footer`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: fit-content;
  padding: 16px;
  background-color: ${Colors.WHITE_100};
  animation: ${slideIn} .5s ease-in-out;
`;

const MobileGuide = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap:12px;
  margin-top: 6px;
  .small-guide{
    color:${Colors.CG_70};
    font:${Fonts.B3_Medium};
    white-space: pre;
  }
  .lead-text{
    display: flex;
    color:${Colors.JOBDA_BLACK};
    font:${Fonts.B3_Medium};
    align-items: center;
  }
`;
interface ISignUpProps {
  login?: Login
  joinModel?: JoinModel;
  context?: Context;
  resumeModel?: ResumeModel;
}

const SignUp: FC<ISignUpProps> = ({ login = new Login(), joinModel = new JoinModel(), resumeModel = new ResumeModel(), context = new Context() }) => {
  const history = useHistory();
  const urlString = new URLSearchParams(history.location.search);
  const utmCampaign = urlString.get('utm_campaign');
  const redirectUrl = urlString.get('redirect_url');

  const { setToastObject } = useToast();
  const [isDiableSendBtn, setIsDiableSendBtn] = useState<boolean>(false);
  const [isVerificate, setIsVerificate] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [randomSeed, setRandomSeed] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isValidId, setIsValidId] = useState<boolean>(false);
  const [isOpenTermsModal, setIsOpenTermsModal] = useState<boolean>(false);
  const [isOpenCompleteModal, setIsOpenCompleteModal] = useState<boolean>(false);
  const [isOpenErrorModal, setIsErrorModalOpen] = useState<boolean>(false);
  const [sessionOpenId, setSessionOpenId] = useState<string>('');
  const [sendMobile, setSendMobile] = useState<string>('');
  const [sessionOpenIdProvider, setSessionOpenIdProvider] = useState<SocialType | null>(null);
  const [currentGender, setCurrentGender] = useState<string>('');
  const [mobileDuplicated, setMobileDuplicated] = useState<boolean>(false);
  const [maskedId, setMaskedId] = useState<string>('');
  const [createdDate, setCreatedDate] = useState<string>('');

  const timerRef = useRef<number>(0);

  const joinSchema = yup.object({
    name: yup.string().required('').matches(/^[a-zA-Z가-힣\s]*$/, '잘못된 이름 형식이에요.'),
    email: yup.string().required('').matches(/^[0-9a-z]+([-_.]*[0-9a-z-_])*@[a-z]+(\.[a-z]{2,})+$/, '잘못된 이메일 형식이에요.'),
    birthDate: yup.string().required('').length(10, '잘못된 생년월일 형식이에요.').test({
      name: 'birthDateValidation',
      test: (birthDate: string | undefined, { createError }) => {
        const customError = birthDate15Validator(birthDate as string);
        if (customError === true) return true;
        return createError({ message: customError.toString() });
      },
    })
      .test('firstDigitCheck', '정확한 생년월일을 8자리로 입력해주세요.', (value) => {
        if (!value) return true;
        return ['1', '2'].includes(value.charAt(0));
      }),
    genderFlag: yup.string().required('성별을 선택하세요.').oneOf(['MALE', 'FEMALE'], '잘못된 성별 형식이에요.'),
    mobile: yup.string().required('').min(7, '잘못된 휴대폰 번호 형식이에요.').max(11, '잘못된 휴대폰 번호 형식이에요.'),
    token: yup.string().required(''),
    id: yup.string().required('').min(5, '5자 이상 입력해 주세요.').matches(/^[0-9a-z\-_]+$/, '잘못된 아이디 형식이에요.'),
    password: yup.string().required('').test({
      name: 'passwordValidation',
      test: (password: string | undefined, { createError }) => {
        const customError = passwordValidator(password as string, watch('id'), watch('email'));
        if (customError === true) return true;
        return createError({ message: customError.toString() });
      },
    }),
    checkPassword: yup.string().required('').test({
      name: 'passwordValidationRe',
      test: (checkPassword: string | undefined, { createError }) => {
        const customError = rePasswordValidator(checkPassword as string, watch('password'));
        if (customError === true) return true;
        return createError({ message: customError.toString() });
      },
    }),
  });

  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(joinSchema),
    defaultValues: {
      name: joinModel.socialJoinInfo?.name || '',
      email: joinModel.socialJoinInfo?.email || '',
      birthDate: '',
      genderFlag: '',
      countryCode: joinModel.socialJoinInfo?.phoneNumber?.split(' ')[0].slice(1) || '82',
      mobile: joinModel.socialJoinInfo?.phoneNumber ? `0${joinModel.socialJoinInfo?.phoneNumber?.split(' ')[1].replace(/-/g, '')}` : '',
      token: '',
      id: '',
      password: '',
      checkPassword: '',
      agreeTermsOfServiceYn: false,
      agreePrivacyCollectionYn: false,
      marketingConsentYn: false,
      marketingSmsConsentYn: false,
      marketingEmailConsentYn: false,
    },
  });
  const { getValues, watch, formState, reset, setError, trigger, setValue } = formMethods;
  const { errors } = formState;

  const name = watch('name');
  const email = watch('email');
  const birthDate = watch('birthDate');
  const genderFlag = watch('genderFlag');
  const countryCode = watch('countryCode');
  const mobile = watch('mobile');
  const token = watch('token');
  const id = watch('id');
  const password = watch('password');
  const checkPassword = watch('checkPassword');

  const onClickBackBtn = () => {
    if (currentStep === 0) {
      if (context.otherPageRedirectUrl) {
        windowOpenUtils(context.otherPageRedirectUrl, '_self');
      } else history.goBack();
    } else {
      setRandomSeed(Math.random());
      setCurrentStep(currentStep - 1);
    }
  };
  // 인증번호 요청 Btn 함수
  const onClickSendBtn = async () => {
    if (!(errors.name || errors.birthDate || errors.genderFlag || errors.countryCode || errors.mobile || errors.email)) {
      const formattedBirthDate = birthDate.replace(/\./g, '-');
      try {
        const isMobileDuplicated = await joinModel.checkMobileDuplicate({ countryCode, mobile });
        if (isMobileDuplicated?.maskedUserId && isMobileDuplicated?.createdDateTime) {
          setMaskedId(isMobileDuplicated.maskedUserId);
          setCreatedDate(DateFormatYYYYMMDDWithDot(isMobileDuplicated.createdDateTime));
          setMobileDuplicated(true);
        }
        setSendMobile(mobile);
        const res = await joinModel.requestVerificationCode({ name, birthDate: formattedBirthDate, countryCode, mobile });
        if (res) {
          window.clearTimeout(timerRef.current);
          setTimer(180000);
          reset({ ...getValues() });
          setToastObject({ isOpen: true, type: 'SUCCESS', message: '인증번호가 발송되었습니다.' });
        } else setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호 발송에 실패했습니다.' });
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    } else setToastObject({ isOpen: true, type: 'ERROR', message: '입력 값들을 다시 확인해 주세요.' });
  };

  // 인증번호 확인 Btn 함수
  const onClickCheckBtn = async () => {
    if (token.length !== 6) setToastObject({ isOpen: true, type: 'ERROR', message: '인증번호를 확인해 주세요.' });
    else if (!(errors.countryCode && errors.mobile && errors.token)) {
      try {
        const res = await joinModel.checkVerificationCode({ countryCode, mobile, token });
        if (res) setIsVerificate(true);
        else {
          setIsVerificate(false);
          setToastObject({ isOpen: true, type: 'ERROR', message: '인증에 실패했습니다.' });
        }
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    }
  };

  // ID 중복검사 확인 Btn 함수
  const onClickCheckDuplicateIdBtn = async () => {
    if (!(errors.id)) {
      try {
        const res = await joinModel.checkDuplicateId({ id: watch('id') });
        if (!res) setIsValidId(true);
        else {
          setIsValidId(false);
          setError('id', { message: '이미 사용중인 아이디입니다.' });
        }
      } catch (e) {
        const err = e as { response: any };
        setToastObject({ isOpen: true, type: 'ERROR', message: err.response.data.message });
      }
    }
  };

  // Step 1. 완료 Btn 함수
  const onClickNextBtn = () => {
    setRandomSeed(Math.random());
    setCurrentStep(currentStep + 1);
    reset({ ...getValues() });
  };

  // Step 2. 완료 Btn 함수
  const onClickCompleteBtn = async () => {
    await trigger();
    if (!isVerificate) {
      setToastObject({ type: 'ERROR', message: '휴대폰 인증을 완료해 주세요.', isOpen: true });
    } else if (!isValidId) {
      setToastObject({ type: 'ERROR', message: '아이디 중복확인을 해 주세요.', isOpen: true });
    } else if (errors.password || errors.checkPassword) {
      setToastObject({ type: 'ERROR', message: '비밀번호를 확인해 주세요.', isOpen: true });
    } else setIsOpenTermsModal(true);
  };

  // Step 3. 완료 Btn 함수
  const onClickTermsCompleteBtn = async () => {
    try {
      await joinModel.join({
        basic: {
          name: getValues('name'),
          birthDate: getValues('birthDate').replace(/\./g, '-'),
          genderFlag: currentGender,
          mobile: getValues('mobile'),
          countryCode: getValues('countryCode'),
        },
        account: {
          email: getValues('email'),
          id: getValues('id'),
          password: getValues('password'),
        },
        terms: {
          agreeTermsOfServiceYn: getValues('agreeTermsOfServiceYn'),
          agreePrivacyCollectionYn: getValues('agreePrivacyCollectionYn'),
          marketingConsentYn: getValues('marketingConsentYn'),
          marketingSmsConsentYn: getValues('marketingSmsConsentYn'),
          marketingEmailConsentYn: getValues('marketingEmailConsentYn'),
        },
        social: {
          openId: joinModel.socialJoinInfo?.openId || sessionOpenId || null,
          openIdProvider: joinModel.socialJoinInfo?.openIdProvider as SocialType || sessionOpenIdProvider || null,
        },
        mobileToken: getValues('token'),
        utm: history.location.search ? history.location.search.substring(1) : '',
      });
      const builderPositionSn = await TodayRenderCheckUtil.getPositionSn('builder');

      if (utmCampaign === 'acca_jbti_2023') {
        await login.login(id, password);
        if (redirectUrl) {
          windowOpenUtils(redirectUrl, '_self');
        } else {
          windowOpenUtils('https://acca.jobda.im/', '_self');
        }
      } else if (builderPositionSn) {
        await builderProcess(builderPositionSn);
      } else {
        setIsOpenTermsModal(false);
        await loginProcess();
        if (context.loginRedirectPath === RoutePaths.matchingGuideVideo || context.loginRedirectPath === RoutePaths.matchingGuideIntoduce || context.loginRedirectPath === RoutePaths.matchingGuide) {
          history.push(context.loginRedirectPath);
        } else {
          history.push(RoutePaths.welcome);
        }
      }
    } catch (e) {
      const err = e as { response: any };
      setToastObject({ type: 'ERROR', message: err.response.data.message, isOpen: true, duration: 2000 });
    }
  };
  const handleGenderChange = (value: string) => {
    setCurrentGender(value);
  };
  // 일반 로그인 함수
  const loginProcess = async () => {
    await login.login(id, password);
    if (joinModel.socialJoinInfo) removeSession('socialInfo');
    if (login.publicKey && login.positionId) login.matchLoginYn = true;
    await login.loadCurrentUser();
  };

  // 빌더 로그인 함수
  const builderProcess = async (positionSn: string) => {
    await login.login(id, password);
    if (joinModel.socialJoinInfo) removeSession('socialInfo');
    const accessToken = await AuthorizeUtil.getAccessToken();
    if (accessToken === null) throw new Error('accessToken is null');
    try {
      await resumeModel.getPublicKey(Number(positionSn));
      const url = `${resumeModel.url}/writeResume?publicKey=${resumeModel.publicKey}&positionId=${resumeModel.positionId}&jobdaToken=Bearer ${accessToken}`;
      windowOpenUtils(url, '_self');
    } catch (error) {
      setIsErrorModalOpen(true);
    }
  };

  useEffect(() => {
    if (!(name && email && birthDate && genderFlag && countryCode && mobile && timer < 120000 && mobile.length > 9) || isVerificate) {
      setIsDiableSendBtn(true);
    } else {
      setIsDiableSendBtn(false);
    }
  }, [name, email, birthDate, genderFlag, countryCode, mobile, isVerificate, timer]);

  useEffect(() => {
    if (mobile !== sendMobile && mobile.length > 9) {
      setTimer(0);
      setIsDiableSendBtn(false);
      setSendMobile('');
    }
    if (timer && !isVerificate && sendMobile) timerRef.current = window.setTimeout(() => setTimer(timer - 1000), 1000);
  }, [timer, isVerificate, mobile, sendMobile]);

  useEffect(() => {
    setIsValidId(false);
  }, [id]);

  useEffect(() => {
    if (redirectUrl) {
      context.otherPageRedirectUrl = redirectUrl;
    }
  }, [redirectUrl]);
  useEffect(() => {
    const init = async () => {
      try {
        const res:any = await getSession('socialInfo');
        const sessionData = JSON.parse(res);
        if (sessionData) {
          setSessionOpenId(sessionData.openId);
          setSessionOpenIdProvider(sessionData.openIdProvider);
          const sessionCountryCode = sessionData.phoneNumber ? `${sessionData.phoneNumber.split(' ')[0].slice(1)}` : '82';
          const sessionMobile = sessionData.phoneNumber ? `0${sessionData.phoneNumber.split(' ')[1].replace(/-/g, '')}` : '';
          if (sessionData.name) {
            setValue('name', sessionData.name);
          }
          if (sessionData.email) {
            setValue('email', sessionData.email);
          }
          if (sessionData.phoneNumber) {
            setValue('countryCode', sessionCountryCode);
            setValue('mobile', sessionMobile);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    init();
  }, []);

  return (
    <Frame>
      <Header>
        <Icon name='large-arrow-left' size={32} onClick={onClickBackBtn} />
        회원가입
      </Header>
      <TitleText key={randomSeed}>
        { currentStep === 0 ? '환영합니다!\n당신의 취업을 진심으로 응원해요' : '이제 마지막 단계예요' }
      </TitleText>
      <form>
        <FormProvider {...formMethods}>
          {/* 회원가입 Step 1 */}
          <Contents aria-hidden={currentStep !== 0}>
            <JDInput type='text' inputMode='text' name='name' label='이름' />
            <SpacingBlock size={16} vertical />
            <BirthGenderFrame>
              <BirthdayBox>
                <JDInput birthFormat type='string' name='birthDate' label='생년월일 8자리 (20010508)' maxLength='10' inputMode='numeric' />
                <SpacingBlock size={16} horizontal />

                <GenderBox>
                  <JDRadioInput
                    type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
                    key={`radio-option-12`}
                    name={`genderFlag`}
                    value={GenderType.MALE}
                    label={(<div className='team'>남자</div>)}
                    onChange={() => handleGenderChange(GenderType.MALE)}
                    checked={currentGender === GenderType.MALE}
                  />
                  <SpacingBlock size={16} horizontal />
                  <JDRadioInput
                    type={JD_RADIO_INPUT_TYPE.ROUND_BOX}
                    key={`radio-option-2`}
                    name={`genderFlag`}
                    value={GenderType.FEMALE}
                    label={(<div className='team'>여자</div>)}
                    onChange={() => handleGenderChange(GenderType.FEMALE)}
                    checked={currentGender === GenderType.FEMALE}
                  />
                </GenderBox>
              </BirthdayBox>
            </BirthGenderFrame>
            <SpacingBlock size={16} vertical />
            <JDInput type='email' inputMode='email' name='email' label='이메일' guideText='* 입사제안, 전형안내 등 중요한 메일 수신에 사용됩니다.' autoCompleteList={makeEmailList(watch('email'))} />
            <SpacingBlock size={16} vertical />
            <PhoneBox>
              <JDInputSelector name='countryCode' label='국가' width='calc((100% - 12px) * .3)' options={countryList} />
              <InnerButtonInput style={{ width: 'calc((100% - 12px) * .7)' }}>
                <JDInput type='tel' name='mobile' label='휴대폰 번호' maxLength='11' inputMode='tel' />
                <div className='postFix'>
                  <InnerButton type='button' disabled={isDiableSendBtn} onClick={onClickSendBtn}>
                    { timer === 0 ? '인증' : '재인증' }
                  </InnerButton>
                </div>
              </InnerButtonInput>
            </PhoneBox>
            {
              mobileDuplicated && (
                <MobileGuide>
                  <div className='small-guide'>{`${maskedId}(${createdDate} 가입) 아이디로 가입된 정보입니다.
가입 계정이 아니라면 이어서 회원가입을 진행해 주세요.`}
                  </div>
                  <div className='lead-text' role='button' onClick={() => history.push(RoutePaths.login)}>해당 계정으로 로그인 <Icon name='arrow-right' size={24} /></div>
                </MobileGuide>
              )
            }
            <SpacingBlock size={16} vertical />
            { timer !== 0 && (
            <InnerButtonInput>
              <JDInput type='string' name='token' label='인증번호' maxLength='6' successText={isVerificate ? '인증이 완료되었어요.' : ''} inputMode='numeric' />
              <div className='postFix'>
                { timer !== 0 && !isVerificate ? <Timer>{ convertMsToMMSS(timer) }</Timer> : null }
                <InnerButton type='button' disabled={token.length !== 6 || timer === 0 || isVerificate} onClick={onClickCheckBtn}>
                  확인
                </InnerButton>
              </div>
            </InnerButtonInput>
            )}
          </Contents>
          {/* 회원가입 Step 2 */}
          <Contents aria-hidden={currentStep === 0}>
            <InnerButtonInput>
              <JDInput type='text' name='id' label='아이디' inputMode='text' successText={isValidId ? '사용 가능한 아이디예요.' : ''} guideText='5-20자, 영문 소문자, 숫자, 특수문자 (-), (_)만 사용' maxLength='20' />
              <div className='postFix'>
                <InnerButton type='button' disabled={id.length < 5 || isValidId} onClick={onClickCheckDuplicateIdBtn}>
                  중복확인
                </InnerButton>
              </div>
            </InnerButtonInput>
            <SpacingBlock size={16} vertical />
            <JDInput type='password' name='password' label='비밀번호' guideText='8-16자, 영문 대·소문자, 숫자, 특수문자 2종류 이상 사용' maxLength='16' onChange={async () => { if (checkPassword !== '') await trigger('checkPassword'); }} />
            <SpacingBlock size={16} vertical />
            <JDInput type='password' name='checkPassword' label='비밀번호 재확인' successText={checkPassword && password.length === checkPassword.length && !errors.checkPassword ? '비밀번호가 일치해요.' : ''} maxLength='16' onChange={async () => { await trigger('checkPassword'); }} />
          </Contents>
          {/* 회원가입 Step 3 */}
          { isOpenTermsModal && <JoinTermsModal onClickComplete={onClickTermsCompleteBtn} onClickClose={() => setIsOpenTermsModal(false)} /> }
        </FormProvider>
      </form>
      <SpacingBlock size={78} vertical />
      <Footer>
        { currentStep === 0
          ? <Button label='다음' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickNextBtn} disabled={!isVerificate || !currentGender} />
          : <Button label='가입 완료' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickCompleteBtn} /> }
      </Footer>
      {/* <SignupCongratulateModal isOpen={isOpenCompleteModal} loginProcess={loginProcess} /> */}
      <BuilderAtsErrorModal isOpen={isOpenErrorModal} onClickClose={() => setIsErrorModalOpen(false)} />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.joinModel, injectStore.resumeModel, injectStore.context)(observer(SignUp));
